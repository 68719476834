import React from 'react';
import PropTypes from 'prop-types';

export default function InserButton({ onClick }) {
  return (
    <h3>
      <button type="button" onClick={onClick} className="btn btn-info">
        <strong>
          <i className="material-icons">add_circle_outline</i> Inserir um novo Cupom
        </strong>
      </button>
    </h3>
  );
}

InserButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
