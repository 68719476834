/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useMemo } from 'react';
import BlankPage from '../../components/BlankPage';
import api from '../../services/api';
import { Button, Input } from './style';
import Table from './Table';
import Filters from './Filter';

export default function Painel() {
  const [response, setResponse] = useState({
    docs: [],
    limit: 0,
    page: 0,
    pages: 0,
    total: 0,
  });
  const [nomeFilter, setNomeFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [cpfFilter, setCpfFilter] = useState('');
  const [telefoneFilter, setTelefoneFilter] = useState('');
  const [page, setPage] = useState(1);
  const [ativo, setAtivo] = useState(true);
  const [aniversario, setAniversario] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [ultimoLancamento, setUltimoLancamento] = useState(0);
  const [ultimoAcesso, setUltimoAcesso] = useState(0);
  const [error, setError] = useState(null);

  const totalInitial = useMemo(
    () => (response.page === 1
      ? response.docs.length
        ? 1
        : 0
      : response.limit * (response.page - 1) + 1),
    [response.page, response.limit, response.docs],
  );

  const totalFinal = useMemo(
    () => (response.page === 1
      ? response.docs.length
      : response.limit * (response.page - 1) + response.docs.length),
    [response.page, response.docs, response.limit],
  );

  function handlePageInput(e) {
    if (!e.target.value) return;
    if (isNaN(e.target.value)) return;
    if (Number(e.target.value) > response.pages) return;
    if (Number(e.target.value) <= 0) return;
    setPage(e.target.value);
  }

  useEffect(() => {
    async function fetchUsers() {
      try {
        const { data } = await api.get('api/user');
        setResponse(data);
      } catch (err) {
        setError(err);
      }
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    async function fetchUserusWithFilter() {
      let filters = '';
      if (nomeFilter) filters += `nome=${nomeFilter}&`;
      if (emailFilter) filters += `email=${emailFilter}&`;
      if (cpfFilter) filters += `cpf=${cpfFilter}&`;
      if (telefoneFilter) filters += `telefone=${telefoneFilter}&`;
      filters += `page=${page}&`;
      try {
        const { data } = await api.get(`api/user?${filters}`);
        data.page = Number(data.page);
        if (page > data.pages) setPage(data.pages);
        setResponse(data);
      } catch (err) {
        setError(err);
      }
    }
    fetchUserusWithFilter();
  }, [nomeFilter, emailFilter, cpfFilter, telefoneFilter, page]);

  return (
    <BlankPage>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header card-header-icon card-header-info">
              <div className="card-icon">
                <i className="material-icons">assignment</i>
              </div>
              <h4 className="card-title">Usuários</h4>
            </div>
            <div className="card-body">
              <br />
              <Filters
                ativo={ativo}
                setAtivo={setAtivo}
                aniversario={aniversario}
                setAniversario={setAniversario}
                month={month}
                setMonth={setMonth}
                ultimoAcesso={ultimoAcesso}
                setUltimoAcesso={setUltimoAcesso}
                ultimoLancamento={ultimoLancamento}
                setUltimoLancamento={setUltimoLancamento}
              />
              <br />
              <br />

              <div className="row">
                <div className="col-md-12">
                  <h6>
                    Exibindo: {totalInitial} - {totalFinal} de {response.total} resultados
                  </h6>
                  <Table
                    response={response}
                    nomeFilter={nomeFilter}
                    setNomeFilter={setNomeFilter}
                    emailFilter={emailFilter}
                    setEmailFilter={setEmailFilter}
                    cpfFilter={cpfFilter}
                    setCpfFilter={setCpfFilter}
                    telefoneFilter={telefoneFilter}
                    setTelefoneFilter={setTelefoneFilter}
                  />
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-3 pull-left">
                  <Button
                    onClick={() => setPage(page - 1)}
                    disabled={response.page.toString() === '1'}
                  >
                    Anterior
                  </Button>
                </div>
                <div className="col-md-6 text-center">
                  Página{' '}
                  <Input
                    className="text-center"
                    type="number"
                    value={page}
                    onChange={handlePageInput}
                  />{' '}
                  de {response.pages}
                </div>
                <div className="col-md-3 pull-right">
                  <Button
                    onClick={() => setPage(page + 1)}
                    disabled={response.page.toString() === response.pages.toString()}
                  >
                    Próximo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlankPage>
  );
}
