import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '../../services/api';

export default function Table({
  response,
  nomeFilter,
  setNomeFilter,
  emailFilter,
  setEmailFilter,
  cpfFilter,
  setCpfFilter,
  telefoneFilter,
  setTelefoneFilter,
}) {
  async function handleClienteAccess(user) {
    try {
      const tokenResponse = await api.post('api/assistente-access', { email: user.email });
      const { token } = tokenResponse.data;
      window.open(`https://app.ondazul.online/?token=${token}`);
    } catch (err) {
      toast.error('Internal Server Error');
    }
  }
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="text-info">
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>CPF</th>
            <th>Telefone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="25%">
              <span className="bmd-form-group">
                <input
                  value={nomeFilter}
                  onChange={e => setNomeFilter(e.target.value)}
                  type="text"
                  placeholder="Filtro"
                  className="form-control"
                />
              </span>
            </td>
            <td width="25%">
              <span className="bmd-form-group">
                <input
                  value={emailFilter}
                  onChange={e => setEmailFilter(e.target.value)}
                  type="text"
                  placeholder="Filtro"
                  className="form-control"
                />
              </span>
            </td>
            <td width="25%">
              <span className="bmd-form-group">
                <input
                  value={cpfFilter}
                  onChange={e => setCpfFilter(e.target.value)}
                  type="text"
                  placeholder="Filtro"
                  className="form-control"
                />
              </span>
            </td>
            <td width="25%">
              <span className="bmd-form-group">
                <input
                  value={telefoneFilter}
                  onChange={e => setTelefoneFilter(e.target.value)}
                  type="text"
                  placeholder="Filtro"
                  className="form-control"
                />
              </span>
            </td>
          </tr>
          {response.docs.map(user => (
            <tr key={user._id}>
              <td width="25%">
                <button type="button" onClick={() => handleClienteAccess(user)}>
                  {user.nome}
                </button>
              </td>
              <td width="25%">{user.email}</td>
              <td width="25%">{user.cpf}</td>
              <td width="25%">{user.telefone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  response: PropTypes.shape().isRequired,
  nomeFilter: PropTypes.string.isRequired,
  setNomeFilter: PropTypes.func.isRequired,
  emailFilter: PropTypes.string.isRequired,
  setEmailFilter: PropTypes.func.isRequired,
  cpfFilter: PropTypes.string.isRequired,
  setCpfFilter: PropTypes.func.isRequired,
  telefoneFilter: PropTypes.string.isRequired,
  setTelefoneFilter: PropTypes.func.isRequired,
};
