import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Item from './Item';
// import CollapsedItem from './CollapsedItem';

export default function SideBarContent({ setPage }) {
  const user = useSelector(state => state.user);
  return (
    <ul className="nav">
      <Item href="/painel" title="Painel de Controle" icon="assessment" setPage={setPage} />

      {user.userType !== 'operacional' && (
        <Item href="/calendario" title="Calendário" icon="calendar_today" setPage={setPage} />
      )}

      {user.user.userType === 'adm' && (
        <Item href="/cupom" title="Cupom" icon="shopping_basket" setPage={setPage} />
      )}

      {user.user.userType === 'adm' && (
        <Item
          href="/cadastro-assistente"
          title="Cadastro de Assistente"
          icon="group_add"
          setPage={setPage}
        />
      )}

      {user.user.userType === 'adm' && (
        <Item
          href="/cadastro-celular"
          title="Cadastro de Celular"
          icon="settings_cell"
          setPage={setPage}
        />
      )}

      {/* <CollapsedItem
        setPage={setPage}
        title="Mapear"
        icon="map"
        idColapse="mapear"
        itens={[
          {
            href: '/orcamento',
            mini: 'OR',
            title: 'Orçamento',
          },
          {
            href: '/patrimonio',
            mini: 'PR',
            title: 'Patrimônio',
          },
          {
            href: '/crencas',
            mini: 'CR',
            title: 'Crenças',
          },
        ]}
      /> */}
    </ul>
  );
}

SideBarContent.propTypes = {
  setPage: PropTypes.func.isRequired,
};
