import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

export default function Filters({
  ativo,
  setAtivo,
  aniversario,
  setAniversario,
  month,
  setMonth,
  ultimoAcesso,
  setUltimoAcesso,
  ultimoLancamento,
  setUltimoLancamento,
}) {
  // ultimoacesso;
  // ultimolancamento;
  return (
    <div className="row">
      <div className="col-md-3">
        <div className="filter">
          <div className="form-check">
            <label htmlFor="check" className="form-check-label">
              <input
                id="check"
                className="form-check-input"
                type="checkbox"
                checked={ativo}
                onChange={() => setAtivo(!ativo)}
              />
              Ativo
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </label>
          </div>
          <div className="form-check">
            <label htmlFor="check" className="form-check-label">
              <input
                id="check"
                className="form-check-input"
                type="checkbox"
                checked={!ativo}
                onChange={() => setAtivo(!ativo)}
              />
              Desativo
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="filter">
          <strong>Aniversário</strong>
          <div style={{ margin: '0px 10px' }}>
            <DatePicker
              selected={new Date()}
              className="form-control"
              // onChange={handleStart}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="filter">
          <strong>Último Lançamento (dias)</strong>
          <input type="number" className="form-control" />
        </div>
      </div>
      <div className="col-md-3">
        <div className="filter">
          <strong>Último Acesso (dias)</strong>
          <input type="number" className="form-control" />
        </div>
      </div>
    </div>
  );
}

Filters.propTypes = {
  ativo: PropTypes.bool.isRequired,
  setAtivo: PropTypes.func.isRequired,
  aniversario: PropTypes.bool.isRequired,
  setAniversario: PropTypes.func.isRequired,
  month: PropTypes.number.isRequired,
  setMonth: PropTypes.func.isRequired,
  ultimoAcesso: PropTypes.number.isRequired,
  setUltimoAcesso: PropTypes.func.isRequired,
  ultimoLancamento: PropTypes.number.isRequired,
  setUltimoLancamento: PropTypes.func.isRequired,
};
