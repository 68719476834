import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import BlankPage from '../../components/BlankPage';
import api from '../../services/api';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [start, setStart] = useState(
    moment()
      .startOf('month')
      .format(),
  );
  const [end, setEnd] = useState(
    moment()
      .endOf('month')
      .format(),
  );

  async function fetchAppointmentData(newStart = start, newEnd = end) {
    if (newStart === start && newEnd === end) return;

    setStart(newStart);
    setEnd(newEnd);

    const { data: appointments } = await api.get(`api/appointment?start=${newStart}&end=${newEnd}`);

    setEvents(
      appointments.map(a => ({
        title: a.user.nome,
        start: a.date,
        end: moment(a.date)
          .add('30', 'minutes')
          .format(),
      })),
    );
  }

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  async function handleDatesRender({ activeStart, activeEnd }) {
    await fetchAppointmentData(moment(activeStart).format(), moment(activeEnd).format());
  }

  const calendarRef = React.createRef();
  return (
    <BlankPage>
      <div className="row">
        <div className="col-md-11 ml-auto mr-auto">
          <div className="card">
            <div className="card-body">
              <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                locale="pt-br"
                plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                weekends={false}
                events={events}
                header={{
                  left: 'prev, next',
                  center: 'title',
                  right: 'dayGridMonth, listWeek, dayGridWeek',
                }}
                datesRender={({ view }) => handleDatesRender(view)}
              />
            </div>
          </div>
        </div>
      </div>
    </BlankPage>
  );
}
