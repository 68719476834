import styled from 'styled-components';

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  width: 40px;
  margin: 0px 15px;
`;

export const Button = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
  padding: 6px;
  font-size: 1em;
  color: #fff;
  background: rgba(0, 179, 219, 0.582);
  transition: all 0.1s ease;
  cursor: ${props => (props.disabled ? 'cursor' : 'pointer')};
  outline: none;
  font-weight: bold;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  &:not([disabled]):hover {
    background: rgb(0, 179, 219);
    color: #fff;
  }
`;
