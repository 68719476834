import React from 'react';
import PropTypes from 'prop-types';

export default function CardCupom({ cupom, handleDelete }) {
  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-header card-header-text card-header-info">
          <div className="card-text">
            <h4 className="card-title">
              <strong>{cupom.name}</strong>
            </h4>
          </div>
          <div className="pull-right">
            <button
              type="button"
              onClick={handleDelete}
              className="btn btn-danger btn-link btn-just-icon btn-sm"
            >
              <i className="material-icons">close</i>
            </button>
          </div>
        </div>
        <div className="card-body">{cupom.description}</div>
      </div>
    </div>
  );
}

CardCupom.propTypes = {
  cupom: PropTypes.shape().isRequired,
  handleDelete: PropTypes.func.isRequired,
};
