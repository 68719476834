import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from './PrivateRoute';
import AdmRoute from './AdmRoute';
import AssistenteRoute from './AssistenteRoute';
import Login from './Login';
import Painel from './Painel';
import Cupom from './Cupom';
import CadastroAssistente from './CadastroAssistente';
import CadastroCelular from './CadastroCelular';
import Calendar from './Calendar';

const Pages = () => (
  <BrowserRouter>
    <ToastContainer autoClose={2000} />
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/painel" component={Painel} />
      <AssistenteRoute exact path="/calendario" component={Calendar} />
      <AdmRoute exact path="/cupom" component={Cupom} />
      <AdmRoute exact path="/cadastro-assistente" component={CadastroAssistente} />
      <AdmRoute exact path="/cadastro-celular" component={CadastroCelular} />
    </Switch>
  </BrowserRouter>
);

export default Pages;
