import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  width: 40px;
  margin: 0px 15px;
`;

export const Button = styled.button`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
  padding: 6px;
  font-size: 1em;
  color: #fff;
  background: rgba(0, 179, 219, 0.582);
  transition: all 0.1s ease;
  cursor: ${props => (props.disabled ? 'cursor' : 'pointer')};
  outline: none;
  font-weight: bold;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  &:not([disabled]):hover {
    background: rgb(0, 179, 219);
    color: #fff;
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled(motion.div)`
  width: 500px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 25px;

  form {
    flex: 1;
    width: 80%;
    margin-top: 30px;
    div.group {
      margin: 10px 0;
    }
  }

  div.footer {
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
  }
`;
