import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Container, Box } from './style';
import 'react-datepicker/dist/react-datepicker.css';

import api from '../../services/api';

export default function Modal({ setModal, setUsers }) {
  const [nascimento, setNascimento] = useState(new Date());
  const [nome, setNome] = useState('');
  const [userType, setUserType] = useState('operacional');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [realNumber, setRealNumber] = useState('');
  const [celulares, setCelulares] = useState([]);

  useEffect(() => {
    async function fetchCelulares() {
      const { data: fetchedCelulares } = await api.get('api/celular');
      const celularDisponivel = fetchedCelulares.filter(celular => !celular.assistente);
      setCelulares(celularDisponivel);
    }
    fetchCelulares();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!nascimento || !nome || !userType || !telefone || !cpf || !email) {
      return toast.error('Insira todos os campos');
    }

    if (userType !== 'assistente' && !realNumber) {
      return toast.error('Insira o celular de lançamento');
    }

    try {
      const { data: newUser } = await api.post('api/realuser', {
        nascimento,
        nome,
        senha: cpf,
        userType,
        telefone,
        cpf,
        email,
        realNumber: userType === 'assistente' ? undefined : realNumber,
      });

      setUsers(data => ({ ...data, docs: [...data.docs, newUser] }));
      setNascimento(new Date());
      setNome('');
      setUserType('assistente');
      setTelefone('');
      setCpf('');
      setEmail('');
      return setModal(false);
    } catch (err) {
      return toast.error('Internal Server Error');
    }
  }

  return (
    <Container>
      <Box initial={{ scale: 0 }} animate={{ scale: 1 }}>
        <h3>Inclusão de usuário</h3>
        <form onSubmit={handleSubmit}>
          <div className="group">
            <span>Email:</span>
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              className="form-control"
            />
          </div>
          <div className="group">
            <span>Tipo de usuário:</span>
            <select
              value={userType}
              onChange={e => setUserType(e.target.value)}
              className="form-control"
              placeholder="Classificação da Categoria"
            >
              <option value="operacional">Operacional</option>
              <option value="assistente">Assistente</option>
            </select>
          </div>
          {userType === 'operacional' && (
            <div className="group">
              <span>Celular para lançar gastos:</span>
              <select
                value={realNumber}
                onChange={e => setRealNumber(e.target.value)}
                className="form-control"
                placeholder="Classificação da Categoria"
              >
                <option value="0">Escolha o número</option>
                {celulares.map(celular => (
                  <option value={celular._id} key={celular.number}>
                    {celular.number}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="group">
            <span>Nome:</span>
            <input
              value={nome}
              onChange={e => setNome(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="group">
            <span>Telefone:</span>
            <input
              value={telefone}
              onChange={e => setTelefone(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="group">
            <span>CPF:</span>
            <input
              value={cpf}
              onChange={e => setCpf(e.target.value)}
              type="text"
              className="form-control"
            />
          </div>
          <div className="group">
            <span>Nascimento:</span>
            <DatePicker
              selected={nascimento}
              onChange={date => setNascimento(date)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>

          <div className="footer">
            <button onClick={() => setModal(false)} className="btn" type="button">
              <strong>Fechar</strong>
            </button>
            <button className="btn btn-success" type="submit">
              <strong>Adicionar</strong>
            </button>
          </div>
        </form>
      </Box>
    </Container>
  );
}

Modal.propTypes = {
  setModal: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
};
