import React from 'react';
import PropTypes from 'prop-types';

export default function newCupom({
  name,
  description,
  setName,
  setDescription,
  close,
  handleNewCupom,
}) {
  return (
    <div className="col-md-4">
      <div className="card">
        <div className="card-header card-header-text card-header-info">
          <div className="card-text">
            <h4 className="card-title">
              <input
                value={name}
                onChange={e => setName(e.target.value.toUpperCase())}
                className="form-control"
                type="text"
                placeholder="Nome do cupom"
                maxLength={6}
              />
            </h4>
          </div>
          <div className="pull-right">
            <button
              onClick={close}
              type="button"
              className="btn btn-danger btn-link btn-just-icon btn-sm"
            >
              <i className="material-icons">close</i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <br />
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
            className="form-control"
            type="text"
            placeholder="Descrição do cupom"
          />
          <br />
          <div className="row">
            <div className="col-md-12">
              <button onClick={handleNewCupom} type="button" className="btn btn-info pull-right">
                <strong>Salvar</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

newCupom.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  handleNewCupom: PropTypes.func.isRequired,
};
