import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../services/api';
import BlankPage from '../../components/BlankPage';

export default function CadastroCelular() {
  const [celulares, setCelulares] = useState([]);
  const [newCelular, setNewCelular] = useState(false);
  const [newNumber, setNewNumber] = useState('');

  useEffect(() => {
    async function fetchCelulares() {
      const { data: fetchedCelulares } = await api.get('api/celular');
      setCelulares(fetchedCelulares);
    }
    fetchCelulares();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!newNumber) return toast.error('Insira o número');
    const { data: celular } = await api.post('api/celular', { number: newNumber });
    setCelulares([...celulares, celular]);
    setNewCelular(false);
    return setNewNumber('');
  }

  return (
    <BlankPage>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="table-responsive">
                <table className="table">
                  <thead className="text-info">
                    <tr>
                      <th>Celular</th>
                      <th>Assistente</th>
                      <th>Quantiade de Clientes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {celulares.map(celular => (
                      <tr key={celular._id}>
                        <td width="33%">{celular.number}</td>
                        <td width="33%">
                          {celular.assistente ? celular.assistente.nome : 'NÃO ALOCADO'}
                        </td>
                        <td width="33%">{celular.clientes.length}</td>
                      </tr>
                    ))}
                    {newCelular && (
                      <tr>
                        <td width="33%">
                          <input
                            value={newNumber}
                            onChange={e => setNewNumber(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Apenas Número..."
                          />
                        </td>
                        <td width="33%">-</td>
                        <td width="33%">-</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <br />

              {newCelular ? (
                <div>
                  <button className="btn btn-info" type="submit">
                    <strong>Adicionar Telefone</strong>
                  </button>
                </div>
              ) : (
                <button onClick={() => setNewCelular(true)} className="btn btn-info" type="button">
                  <strong>Adicionar Telefone</strong>
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </BlankPage>
  );
}
