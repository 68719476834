import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export default function AssistenteRoute({ component: Component, ...rest }) {
  const user = useSelector(state => state.user);

  return (
    <Route
      {...rest}
      render={props => (localStorage.getItem('@OndazulADM: token') && user.user.userType !== 'operacional' ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ))
      }
    />
  );
}

AssistenteRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape(),
};

AssistenteRoute.defaultProps = {
  location: {},
};
