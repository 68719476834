import React from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';
import Pages from './pages';
import store from './store';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const App = () => (
  <Provider store={store}>
    <Pages />
  </Provider>
);

export default App;
