/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import BlankPage from '../../components/BlankPage';
import { Button, Input } from './style';
import Modal from './Modal';

export default function CadastroAssistente() {
  const [users, setUsers] = useState({
    docs: [],
    limit: 0,
    page: 0,
    pages: 0,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      const { data: fetchedUsers } = await api.get('api/realuser');
      setUsers(fetchedUsers);
    }
    fetchUsers();
  }, []);

  function handlePageInput(e) {
    if (!e.target.value) return;
    if (isNaN(e.target.value)) return;
    if (Number(e.target.value) > users.pages) return;
    if (Number(e.target.value) <= 0) return;
    setPage(e.target.value);
  }

  return (
    <>
      {modal && <Modal setModal={setModal} setUsers={setUsers} />}
      <BlankPage>
        <div className="row">
          <button onClick={() => setModal(true)} type="button" className="btn btn-info">
            <strong>Adicionar usuário</strong>
          </button>
        </div>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead className="text-info">
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Nível</th>
                      <th>Telefone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.docs.map(user => (
                      <tr key={user._id}>
                        <td>{user.nome}</td>
                        <td>{user.email}</td>
                        <td>{user.userType}</td>
                        <td>{user.telefone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <div className="row">
                <div className="col-md-3 pull-left">
                  <Button
                    onClick={() => setPage(page - 1)}
                    disabled={users.page.toString() === '1'}
                  >
                    Anterior
                  </Button>
                </div>
                <div className="col-md-6 text-center">
                  Página{' '}
                  <Input
                    className="text-center"
                    type="number"
                    value={page}
                    onChange={handlePageInput}
                  />{' '}
                  de {users.pages}
                </div>
                <div className="col-md-3 pull-right">
                  <Button
                    onClick={() => setPage(page + 1)}
                    disabled={users.page.toString() === users.pages.toString()}
                  >
                    Próximo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlankPage>
    </>
  );
}
