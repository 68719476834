import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Upper from './Upper';
import { Creators as UserActions } from '../../store/ducks/user';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ loginRequest, user, history }) => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !senha) return toast.error('Preencha todos os campos');
    return loginRequest(email, senha);
  };

  useEffect(() => {
    if (user.err) toast.error(user.err);
    if (user.success) {
      if (!history.location.state) {
        history.push('/painel');
      } else {
        history.push(history.location.state.from.pathname);
      }
    }
  }, [user, history]);
  return !user.success && localStorage.getItem('@OndazulADM: token') ? (
    <Redirect to="/painel" />
  ) : (
    <>
      <Upper />
      <div className="wrapper wrapper-full-page">
        <div
          className="page-header login-page header-filter"
          filter-color="black"
          style={{
            backgroundImage: "url('assets/img/login.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                <div className="card card-login">
                  <div className="card-header card-header-info text-center">
                    <h4 className="card-title">ONDAZUL ADM</h4>
                  </div>
                  <br />

                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <p className="card-description text-center">
                        <strong>FAÇA O LOGIN</strong>
                      </p>
                      <div className="card-footer justify-content-center" />

                      <span className="bmd-form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">email</i>
                            </span>
                          </div>
                          <br />
                          <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            name="email"
                            className="form-control"
                            placeholder="Email..."
                          />
                        </div>
                      </span>

                      <span className="bmd-form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">lock_outline</i>
                            </span>
                          </div>
                          <br />
                          <input
                            name="senha"
                            type="password"
                            value={senha}
                            onChange={e => setSenha(e.target.value)}
                            className="form-control"
                            placeholder="Password..."
                          />
                        </div>
                      </span>
                    </div>

                    <br />

                    <div className="card-footer justify-content-center">
                      <button type="submit" className="btn btn-info btn-lg">
                        {user.loading ? (
                          <i className="fa fa-spinner fa-pulse" />
                        ) : (
                          <strong>Vamos Lá!</strong>
                        )}
                      </button>
                    </div>
                  </form>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
