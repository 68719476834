import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import SideBar from './SideBar';
import MainPanel from './MainPanel';
import TopNavBar from './TopNavBar';

const BlankPage = ({ children }) => {
  const [page, setPage] = useState('');

  return (
    <>
      <Wrapper>
        <SideBar setPage={setPage} />
        <MainPanel>
          <TopNavBar page={page} />
          <div className="content">
            <div className="container-fluid">
              <>{children}</>
            </div>
          </div>
        </MainPanel>
      </Wrapper>
    </>
  );
};

BlankPage.propTypes = {
  children: PropTypes.node,
};

BlankPage.defaultProps = {
  children: '',
};

export default BlankPage;
