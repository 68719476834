/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import BlankPage from '../../components/BlankPage';
import api from '../../services/api';
import CardCupom from './CardCupom';
import InserButton from './InsertButton';
import NewCupom from './NewCupom';

export default function Cupom() {
  const [cupons, setCupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newCupom, setNewCupom] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  async function handleDelete(cupom) {
    try {
      await api.delete(`api/cupom/${cupom._id}`);
      setCupons(cupons.filter(c => c._id !== cupom._id));
    } catch (err) {
      toast.error(err.response.data.error || 'Internal Server Error');
    }
  }

  async function handleNewCupom() {
    if (!name || !description) return toast.error('Insira todos os campos');
    if (name.length > 6) return toast.error('Cupom devet ter no máximo 6 caracteres');
    if (isNaN(name.slice(-2))) return toast.error('O final do nome do cupom deve ser os números referentes ao desconto');

    try {
      const { data: cupom } = await api.post('api/cupom', {
        name,
        description,
        discount: name.slice(-2),
      });
      setCupons([cupom, ...cupons]);
    } catch (err) {
      toast.error(err.response.data.error || 'Internal Server Error');
    }
    return setNewCupom(false);
  }

  useEffect(() => {
    async function fetchCupons() {
      setLoading(true);
      try {
        const { data } = await api.get('api/cupom');
        setCupons(data);
      } catch (err) {
        toast.error(err.response.data.error || 'Internal Server Error');
      }
      setLoading(false);
    }
    fetchCupons();
  }, []);

  return (
    <BlankPage>
      {loading ? (
        <h2>...Loading</h2>
      ) : (
        <>
          <InserButton onClick={() => setNewCupom(true)} />
          <br />
          <br />
          <div className="row">
            {newCupom && (
              <NewCupom
                handleNewCupom={handleNewCupom}
                close={() => setNewCupom(false)}
                name={name}
                description={description}
                setName={setName}
                setDescription={setDescription}
              />
            )}
            {cupons.map(cupom => (
              <CardCupom key={cupom._id} handleDelete={() => handleDelete(cupom)} cupom={cupom} />
            ))}
          </div>
        </>
      )}
    </BlankPage>
  );
}
